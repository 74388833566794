import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ServicePath } from '../../common/constant-service-api';
import { API } from '../api';

const SERVICE_REGISTER_CONSUMER = ServicePath.MANAGEMENT_SERVICE + '/user-informations/register';
const SERVICE_REGISTER_BUSINESS = ServicePath.MANAGEMENT_SERVICE + '/parties/register';
const SERVICE_REGISTER_BUSINESS_VIP = ServicePath.MANAGEMENT_SERVICE + '/parties/register-vip';
const SERVICE_RESEND_OTP = ServicePath.MANAGEMENT_SERVICE + '/user-informations';
const SERVICE_VERIFY_OTP = ServicePath.MANAGEMENT_SERVICE + '/user-informations';
const SERVICE_RESEND_EMAIL = ServicePath.MANAGEMENT_SERVICE + '/parties';
const SERVICE_VERIFY_EMAIL = ServicePath.MANAGEMENT_SERVICE + '/parties';
const SERVICE_VERIFY_VIP_EMAIL = ServicePath.MANAGEMENT_SERVICE + '/parties';
const SERVICE_CREATE_FORGOT_PASSWORD = ServicePath.AUTH_SERVICE + '/create-forgot-password';
const SERVICE_CREATE_FORGOT_PASSWORD_V2 = ServicePath.AUTH_SERVICE + '/v2/forgot-account/forgot-by-email';
const SERVICE_CREATE_FORGOT_PASSWORD_V3 = ServicePath.AUTH_SERVICE + '/v3/create-forgot-password';
const SERVICE_CONFIRM_FORGOT_PASSWORD = ServicePath.AUTH_SERVICE + '/confirm-forgot-password';
const SERVICE_CONFIRM_FORGOT_PASSWORD_V2 = ServicePath.AUTH_SERVICE +'/v2/forgot-account/confirm-by-email';
const SERVICE_CONFIRM_FORGOT_PASSWORD_V3 = ServicePath.AUTH_SERVICE +'/v3/forgot-account/confirm';
const SERVICE_CONFIRM_CHANGE_PASSWORD = ServicePath.AUTH_SERVICE + '/confirm-change-password';
const SERVICE_CONFIRM_CHANGE_PASSWORD_V2 = ServicePath.AUTH_SERVICE + '/v2/forgot-account/confirm-change-password';
const SERVICE_GET_USER_INFO = ServicePath.MANAGEMENT_SERVICE + '/v2/user-informations';
const SERVICE_GET_PARTY_INFO = ServicePath.MANAGEMENT_SERVICE + '/parties';
const SERVICE_UPDATE_INFO_CONSUMER = ServicePath.MANAGEMENT_SERVICE + '/user-informations';
const SERVICE_UPDATE_INFO_PARTY = ServicePath.MANAGEMENT_SERVICE + '/parties';
const SERVICE_UPLOAD_GPKD = ServicePath.MANAGEMENT_SERVICE + '/parties';
const SERVICE_COMPLETE_UPLOAD_GPKD = ServicePath.MANAGEMENT_SERVICE + '/parties';
const SERVICE_REGISTER_WAITING_APPROVE = ServicePath.MANAGEMENT_SERVICE + '/parties/register-waiting-approve';
const SERVICE_GET_ALL_PRIVILEGE = ServicePath.AUTH_SERVICE + "/privilege/all-privileges";
const DOWNLOAD_GPKD = ServicePath.MANAGEMENT_SERVICE + '/parties';
const ROLES = ServicePath.AUTH_SERVICE + '/account/roles';
const SERVICE_REGISTER_CONSUMER_BY_INVITED = ServicePath.MANAGEMENT_SERVICE + '/user-informations/register-by-invited';
const SERVICE_REGISTER_BUSINESS_BY_INVITED = ServicePath.MANAGEMENT_SERVICE + '/parties/register-by-invited';
const SERVICE_REGISTER_BUSINESS_VIP_BY_INVITED = ServicePath.MANAGEMENT_SERVICE + '/parties/register-vip-by-invited';
const LOGIN_BY_OTP = ServicePath.AUTH_SERVICE + '/v2/tech-lab/forgot-account/login-by-otp';
const VERIFY_LOGIN_OTP = ServicePath.AUTH_SERVICE + '/v2/forgot-account/confirm-login-otp';

const SDK_LOGIN_BY_OTP = ServicePath.AUTH_SERVICE + '/v2/forgot-account/otp-login';
const SDK_VERIFY_LOGIN_OTP = ServicePath.AUTH_SERVICE + '/v2/forgot-account/otp-login-confirm';

const CHECK_RENEW_PASSWORD_TOKEN= ServicePath.AUTH_SERVICE+ '/check-renew-password-token?token='
@Injectable({
  providedIn: 'root'
})
export class AasAuthService {
  constructor(
    private router: Router,
    private api: API
  ) { }

  //MARK: Request for Service pages
  /**
   * Request đăng ký tài khoản cá nhân
   * @param param 
   */
  postRegisterConsumer(param) {
    const url = SERVICE_REGISTER_CONSUMER;
    return this.api.post(url, param);
  }

  /**
   * Request đăng ký tài khoản doanh nghiệp
   * @param param 
   */
  postRegisterBusiness(param) {
    const url = SERVICE_REGISTER_BUSINESS;
    return this.api.post(url, param);
  }

  /**
   * Request đăng ký tài khoản doanh nghiệp vip
   * @param param 
   */
  postRegisterBusinessVip(param) {
    const url = SERVICE_REGISTER_BUSINESS_VIP;
    return this.api.post(url, param);
  }

  /**
   * Request verify OTP
   * @param id 
   * @param otp 
   */
  verifyOTP(id, otp) {
    const url = SERVICE_VERIFY_OTP + `/${id}/verify-otp?otp=${otp}`;
    return this.api.post(url, null);
  }

  /**
   * Request gửi lại OTP
   * @param id 
   */
  resendOTP(id) {
    const url = SERVICE_RESEND_OTP + `/${id}/resend-otp`;
    return this.api.post(url, null);
  }

  /**
   * Request verify OTP email
   * @param id 
   * @param otp 
   */
  verifyEmail(id, otp) {
    const url = SERVICE_VERIFY_EMAIL + `/${id}/verify-email-register?otp=${otp}`;
    return this.api.post(url, null);
  }

  /**
   * Request verify OTP email (ENTERPRISE)
   * @param id 
   * @param otp 
   */
  verifyVipEmail(id, otp) {
    const url = SERVICE_VERIFY_VIP_EMAIL + `/${id}/verify-email-register-vip?otp=${otp}`;
    return this.api.post(url, null);
  }

  /**
   * Request gui lai OTP email
   * @param id 
   */
  resendEmail(id) {
    const url = SERVICE_RESEND_EMAIL + `/${id}/resend-email-register`;
    return this.api.post(url, null);
  }

  /**
   * Request tạo yêu cầu quên mật khẩu
   * @param type //CONSUMER - BUSINESS
   * @param user 
   * @param domain 
   */
  postCreateForgotPassword(type, user, domain = '') {
    let url = '';
    switch (type) {
      case 'CONSUMER':
        url = SERVICE_CREATE_FORGOT_PASSWORD + `?phoneNumber=${user}&domain=${domain}`;
        break;
      case 'BUSINESS':
        url = SERVICE_CREATE_FORGOT_PASSWORD + `?email=${user}&domain=${domain}`;
        break;
    }

    return this.api.post(url, null);
  }

  /**
   * Request tạo yêu cầu quên mật khẩu
   * @param user 
   * @param domain 
   */
   postCreateForgotPasswordV2(email, domain = '') {
    const url = SERVICE_CREATE_FORGOT_PASSWORD_V2;
    const data = {
      "email": email,
      "domain": domain
    }
    return this.api.post(url, data);
  }

  // Request tạo yêu cầu quên mật khẩu V3
   postCreateForgotPasswordV3(email,type, domain = '') {
    const url = SERVICE_CREATE_FORGOT_PASSWORD_V3 ;
    let data = {
      "domain": domain,
      "type": type
    }

    if (type == 1){
      data["email"] = email
    }
    else if (type == 2){
      data["phoneNumber"] = email
    }
    
    return this.api.post(url,data);
  }

  /**
   * Request Conform OTP cho quên mật khẩu bằng sđt
   * @param user 
   * @param token 
   * @param param 
   */
  postConfirmForgotPassword(user, token, domain, param = null) {
    const url = SERVICE_CONFIRM_FORGOT_PASSWORD + `?phoneNumber=${user}&otp=${token}&domain=${domain}`;
    return this.api.post(url, param);
  }

  /**
   * Request Conform OTP cho quên mật khẩu bằng sđt
   * @param user 
   * @param otp 
   */
   postConfirmForgotPasswordV2(email, otp, domain = '') {
    const url = SERVICE_CONFIRM_FORGOT_PASSWORD_V2;
    const data = {
      "email": email,
      "domain": domain,
      "otp": otp
    }
    return this.api.post(url, data);
  }

  // Request Conform OTP cho quên mật khẩu bằng email/sđt
   postConfirmForgotPasswordV3(emailOrPhone,type, otp, domain = '') {
    const url = SERVICE_CONFIRM_FORGOT_PASSWORD_V3;
    let data = {
      "domain": domain,
      "otp": otp
    }
    if (type == 1){
      data["email"] = emailOrPhone
    }
    else if (type == 2){
      data["phoneNumber"] = emailOrPhone
    }
    return this.api.post(url, data);
  }

  /**
   * Request đổi mật khẩu mới
   * @param type 
   * @param user 
   * @param token 
   * @param param 
   */
  postConfirmChangePassword(email, phoneNumber, token, param = null) {
    const url = SERVICE_CONFIRM_CHANGE_PASSWORD + `?token=${token}&email=${email}&phoneNumber=${phoneNumber}`;
    return this.api.post(url, param);
  }

  /**
   * Request đổi mật khẩu mới V2
   * @param type 
   * @param user 
   * @param token 
   * @param param 
   */
   postConfirmChangePasswordV2(emailOrPhone, confirmationToken, password, passwordConfirm) {
    const url = SERVICE_CONFIRM_CHANGE_PASSWORD_V2;
    const data = {
      "emailOrPhone": emailOrPhone,
      "confirmationToken": confirmationToken,
      "password": password,
      "passwordConfirm": passwordConfirm
    }
    return this.api.post(url, data);
  }

  /**
   * Request lấy thông tin tài khoản người dùng
   * @param id 
   */
  getUserInfo(id) {
    const url = SERVICE_GET_USER_INFO + `/${id}`;
    return this.api.get(url);
  }

  /**
   * Request lấy thông tin tổ chức
   * @param partyId 
   */
  getPartyInfo(partyId) {
    const url = SERVICE_GET_PARTY_INFO + `/${partyId}`;
    return this.api.get(url);
  }  

  /**
   * Request cập nhật thông tin ng dùng cá nhân
   * @param id 
   * @param param 
   */
  postUpdateConsumer(id, param) {
    const url = SERVICE_UPDATE_INFO_CONSUMER + `/${id}/update-info-l1`;
    return this.api.post(url, param);
  }

  /**
   * Request cập nhật thông tin doanh nghiệp
   * @param id 
   * @param param 
   */
  postUpdateBusiness(id, param) {
    const url = SERVICE_UPDATE_INFO_PARTY + `/${id}/update-info-l1`;
    return this.api.post(url, param);
  }

  /**
   * Request upload gpkd
   * @param id 
   * @param data 
   */
  postUploadGPKD(id, data) {
    const url = SERVICE_UPLOAD_GPKD + `/${id}/upload-gpkd`;
    return this.api.postMultiPartJSON(url, data);
  }

  /**
   * Request hoàn thành upload gpkd
   * @param id 
   * @param data 
   */
  postCompleteVerifyGPKD(id, data) {
    const url = SERVICE_COMPLETE_UPLOAD_GPKD + `/${id}/complete-verify-gpkd`;
    return this.api.postMultiPartJSON(url, data);
  }

  /**
   * Gửi yêu cầu phê duyệt
   * @param data 
   */
  postRegisterWaitingApprove(data) {
    const url = SERVICE_REGISTER_WAITING_APPROVE;
    return this.api.post(url, data);
  }

  /**
   * Get Danh sách tất cả các quyền
   */
  getAllPrivileges() {
    const url = SERVICE_GET_ALL_PRIVILEGE;
    return this.api.get(url);
  }

  /**
   * Tai file GPKD
   * @param partyId 
   */
  downloadGPKD(partyId) {
    const url = DOWNLOAD_GPKD + `/${partyId}/download-gpkd`;
    return this.api.download(url);
  }

  /**
   * Lay roles cua account
   */
  getAccountRoles() {
    const url = ROLES;
    return this.api.get(url);
  }

  //MARK: Request dang ky tai khoan duoc moi
  /**
   * Request đăng ký tài khoản cá nhân được mời
   * @param param 
   */
  postRegisterConsumerByInvited(id, param) {
    const url = SERVICE_REGISTER_CONSUMER_BY_INVITED + `?id=${id}`;
    return this.api.post(url, param);
  }

  /**
   * Request đăng ký tài khoản doanh nghiệp được mời
   * @param param 
   */
  postRegisterBusinessByInvited(id, param) {
    const url = SERVICE_REGISTER_BUSINESS_BY_INVITED + `?id=${id}`;
    return this.api.post(url, param);
  }

  /**
   * Request đăng ký tài khoản doanh nghiệp vip được mời
   * @param param 
   */
  postRegisterBusinessVipByInvited(id, param) {
    const url = SERVICE_REGISTER_BUSINESS_VIP_BY_INVITED + `?id=${id}`;
    return this.api.post(url, param);
  }

   /**
   * Đăng nhập bằng OTP
   * @param username 
   * @param domain 
   * @returns 
   */
  loginByOtp(username: string, domain: string) {
    const data = {
      "userName": username,
      "domain": domain
    };
    return this.api.post(LOGIN_BY_OTP, data);
  }

  /**
   * Xác nhận OTP đăng nhập
   * @param username 
   * @param domain 
   * @param otp 
   * @param clientId 
   * @returns 
   */
  verifyLoginOtp(username: string, domain: string, otp: string, clientId: string = "") {
    const data = {
      "userName": username,
      "domain": domain,
      "otp": otp,
      "clientId": clientId
    };
    return this.api.post(VERIFY_LOGIN_OTP, data);
  }

  /**
   * SDK Đăng nhập bằng OTP
   * @param username 
   * @param domain 
   * @returns 
   */
   sdkLoginByOtp(username: string, domain: string, clientId: string, clientSecret: string) {
    const data = {
      "userName": username,
      "domain": domain,
      "clientId": clientId,
      // "clientSecret": clientSecret
    };
    return this.api.post(SDK_LOGIN_BY_OTP, data);
  }

  /**
   * SDK Xác nhận OTP đăng nhập
   * @param username 
   * @param domain 
   * @param otp 
   * @param clientId 
   * @returns 
   */
  sdkVerifyLoginOtp(username: string, domain: string, otp: string, clientId: string = "", clientSecret: string = "") {
    const data = {
      "userName": username,
      "domain": domain,
      "otp": otp,
      "clientId": clientId,
      // "clientSecret": clientSecret
    };
    return this.api.post(SDK_VERIFY_LOGIN_OTP, data);
  }
  
  checkRenewPasswordToken(token){
    const url = CHECK_RENEW_PASSWORD_TOKEN + `${token}`
    return this.api.get(url)
  }
}
